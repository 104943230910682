import React, {useState} from 'react';
import {useHistory} from "react-router-dom";
import axios from "axios";
import Constant from "../../../Constant";
import background from "../../../Assets/Image/login-bg.jpg";

const ForgetPassword = () => {
    const history = useHistory()
    const [input, setInput] = useState({email: ''})
    const [isLoading, setIsLoading] = useState(false)
    const [errors, setErrors] = useState([])
    const [err, setErr] = useState('')

    const handleInput = (e) => setInput(prevState => ({...prevState, [e.target.name]: e.target.value}))

    const handleResetPassword = () => {
        setIsLoading(true)
        axios.post(Constant.BASE_URL + '/forget-password', input).then(res => {
            setErrors([])
            setErr(res.data.message)
            setIsLoading(false)
        }).catch(errors => {
            if (errors.response.status === 422) {
                setErrors(errors.response.data.errors)
                setIsLoading(false)
            }
        })
    }

    return (
        <div className={'login-container'}>
            <img src={background} alt={'Login Image'}/>
            <div className="login-card">
                    <h4  className={'text-center text-white mb-4'}>Forget password</h4>


                    <label className={'w-100 text-start  text-white position-relative'}>
                        Email
                        <input
                            type={'text'}
                            className={'form-control'}
                            name={'email'}
                            value={input.email}
                            onChange={handleInput}
                        />
                        <p className={'login-error-msg'}><small>{errors.email !== undefined ? errors.email[0] : null}</small></p>
                        <p className={'login-error-msg'}><small>{err !=undefined ? err: null}</small></p>
                    </label>
                    <div className="d-grid mt-2">
                        <button disabled={isLoading} onClick={handleResetPassword} type={'button'} className={'btn btn-primary'}>
                            {isLoading ?
                                <div className="spinner-border spinner-border-sm" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>: 'Reset Password'}

                        </button>
                    </div>

            </div>
        </div>

    );
};

export default ForgetPassword;
