import React, {useEffect, useState} from 'react';
import axios from "axios";
import Constant from "../../../Constant";
import {Helmet} from "react-helmet";
import BreadCrumb from "../../Includes/BreadCrumb";
import loader from "../../../Assets/Image/loader/loader.svg";
import {Link} from "react-router-dom";

const AdminList = () => {
    const [admins, setAdmins] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const getAdmin = () => {
        setIsLoading(true)
        axios.get(`${Constant.BASE_URL}/get-admin`).then(res => {
            setAdmins(res.data.data)
            setIsLoading(false)
        })
    }
    useEffect(() => {
        getAdmin()
    }, []);

    return (
        <div className="container-fluid px-4">
            <Helmet>
                <title>Admin List | Mega Multi Service IO</title>
            </Helmet>
            <BreadCrumb title={'Admin List'}/>
            <div className="row justify-content-center">
                <div className="col-md-12">
                    <div className="card my-card mb-5 position-relative">
                        {isLoading ?
                            <div className="card-loader">
                                <img src={loader} alt={'loader'}/>
                            </div> : null
                        }

                        <div className="card-header d-flex justify-content-between">
                            <h4 className={'theme-text'}>Admin List</h4>
                            <Link to={'/admin/create'}>
                                <button className={'btn btn-success btn-sm'}><i className={'fa-solid fa-plus'}/>
                                </button>
                            </Link>
                        </div>
                        <div className="card-body">
                            {Object.keys(admins).length > 0 ?
                                <table className={'table table-bordered table-hover table-striped'}>
                                    <thead>
                                    <tr>
                                        <th>SL</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Role</th>
                                        <th>Created at</th>
                                        <th>Updated at</th>
                                        <th>Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {admins.map((admin, index) => (
                                        <tr key={index}>
                                            <td>{++index}</td>
                                            <td>{admin.name} </td>
                                            <td>{admin.email}</td>
                                            <td>{admin.role}</td>
                                            <td>{admin.created_at}</td>
                                            <td>{admin.updated_at}</td>
                                            <td>
                                                <Link
                                                    to={`/admin/edit/${admin.id}`}
                                                >
                                                    <button
                                                        className={'btn btn-warning btn-sm'}
                                                    >
                                                        <i className={'fa-solid fa-pen-to-square'}></i>
                                                    </button>
                                                </Link>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table> : null
                            }

                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminList;
