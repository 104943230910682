import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import axios from "axios";
import Constants from "../../../Constant";
import {Helmet} from "react-helmet";
import BreadCrumb from "../../Includes/BreadCrumb";

const SelectListingType = () => {
    const [types, setTypes] = useState([])
    const getTypes = () => {
        axios.get(Constants.BASE_URL + '/get-types').then(res => {
            setTypes(res.data.data)
        })
    }
    useEffect(() => {
        getTypes()
    }, []);


    return (
        <div className="container px-4">
            <Helmet>
                <title>Select Listing Type | Mega Multi Service IO</title>
            </Helmet>
            <BreadCrumb title={'Select Listing Type'}/>
            <div className="row mt-5">
                {types.map((type, index)=>(
                    <div className="col-md-4" key={index}>
                        <Link className={'link-type'} to={`/select-category/${type.id}`}>
                            <div className="card my-card-front place-card">
                                <div className="card-body">
                                    <i className={`fa-solid fa-${type.fa_icon} fa-2x`}/>
                                    <h4>{type.title}</h4>
                                </div>
                            </div>
                        </Link>
                    </div>
                ))}

            </div>
        </div>
    );
};

export default SelectListingType;
