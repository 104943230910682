import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import BreadCrumb from "../../Includes/BreadCrumb";
import {Link} from "react-router-dom";
import axios from "axios";
import Constant from "../../../Constant";
import Pagination from "react-js-pagination";
import Swal from "sweetalert2";
import loader from './../../../Assets/Image/loader/loader.svg'

const ContactList = () => {

    const [contacts, setContacts] = useState([])
    const [startFrom, setStartFrom] = useState(1)
    const [itemsCountPerPage, setItemsCountPerPage] = useState(0)
    const [totalItemsCount, setTotalItemsCount] = useState(0)
    const [activePage, setActivePage] = useState(1)
    const [isLoading, setIsLoading] = useState(false)

    const getListings = (pageNumber, type = 'regular') => {
        setActivePage(pageNumber)
        setIsLoading(true)
        axios.get(`${Constant.BASE_URL}/contact?page=${pageNumber}`).then(res => {
            setContacts(res.data.data)
            setItemsCountPerPage(parseInt(res.data.meta.per_page))
            setTotalItemsCount(res.data.meta.total)
            setIsLoading(false)
            setStartFrom(res.data.meta.from)
        })
    }

    const handleListingDelete = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "contact Will be deleted and also associated all data will be deleted",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`${Constant.BASE_URL}/contact/${id}`).then(res => {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'warning',
                        toast: true,
                        title: res.data.msg,
                        showConfirmButton: false,
                        timer: 3000
                    })
                    getListings(1)
                })
            }
        })
    }

    useEffect(() => {
        getListings(1)
    }, [])


    return (
        <div className="container-fluid px-4">
            <Helmet>
                <title>Contact List | Mega Multi Service IO</title>
            </Helmet>
            <BreadCrumb title={'contact List'}/>
            <div className="row justify-content-center">
                <div className="col-md-12">
                    <div className="card my-card mb-5 position-relative">
                        {isLoading ?
                            <div className="card-loader">
                                <img src={loader} alt={'loader'}/>
                            </div> : null
                        }

                        <div className="card-header d-flex justify-content-between">
                            <h4 className={'theme-text'}>Contact List</h4>
                        </div>
                        <div className="card-body">
                            {Object.keys(contacts).length > 0 ?
                                <table className={'table table-sm table-bordered table-hover table-striped'}>
                                    <thead>
                                    <tr>
                                        <th>SL</th>
                                        <th>Sender Details</th>
                                        <th>Subject</th>
                                        <th style={{maxWidth: '150px'}}>Message</th>
                                        <th>Listing Title</th>
                                        <th>Created At</th>
                                        <th>Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {contacts.map((contact, index) => (
                                        <tr key={index}>
                                            <td className={'align-middle'}>{startFrom + (index++)}</td>
                                            <td>
                                                <p className="mb-0 d-flex align-items-center"><i className="fa-solid fa-user me-1"/> {contact.name}</p>
                                                <p className="mb-0 text-success d-flex align-items-center"><i className="fa-solid fa-envelope me-1"/> {contact.email}</p>
                                                <p className="mb-0 d-flex align-items-center"><i className="fa-solid fa-phone me-1"/> {contact.phone}</p>
                                            </td>
                                            <td>{contact.subject}</td>
                                            <td>{contact.message}</td>
                                            <td>{contact.listing_title}</td>
                                            <td>{contact.created_at}</td>
                                            <td>Action</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table> :null
                            }
                            {Object.keys(contacts).length === 0 && !isLoading ?
                                <div className={'text-center'}><p className={'text-danger'}>No Data Found</p></div>:null
                            }
                        </div>

                        <div className="card-footer">
                            <nav className={'pagination-sm'}>
                                <Pagination
                                    activePage={activePage}
                                    itemsCountPerPage={itemsCountPerPage}
                                    totalItemsCount={totalItemsCount}
                                    pageRangeDisplayed={5}
                                    onChange={getListings}
                                    nextPageText="Next"
                                    prevPageText="Previous"
                                    firstPageText="First"
                                    lastPageText="Last"
                                    itemClass="page-item"
                                    linkClass="page-link"
                                />
                            </nav>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactList;
