import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import BreadCrumb from "../../Includes/BreadCrumb";
import loader from "../../../Assets/Image/loader/loader.svg";
import Pagination from "react-js-pagination";
import axios from "axios";
import Constant from "../../../Constant";
import {Link} from "react-router-dom";
import Swal from "sweetalert2";

const CategoryList = () => {

    const [categories, setCategories] = useState([])
    const [startFrom, setStartFrom] = useState(1)
    const [itemsCountPerPage, setItemsCountPerPage] = useState(0)
    const [totalItemsCount, setTotalItemsCount] = useState(0)
    const [activePage, setActivePage] = useState(1)
    const [isLoading, setIsLoading] = useState(false)

    const getCategories = (pageNumber) => {
        setActivePage(pageNumber)
        setIsLoading(true)
        axios.get(`${Constant.BASE_URL}/category?page=${pageNumber}`).then(res => {
            setCategories(res.data.data)
            setItemsCountPerPage(parseInt(res.data.meta.per_page))
            setTotalItemsCount(res.data.meta.total)
            setIsLoading(false)
            setStartFrom(res.data.meta.from)
        })
    }

    const handleCategoryDelete = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "Category will be deleted also all listing related to this category will be deleted",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`${Constant.BASE_URL}/category/${id}`).then(res=>{
                    getCategories()
                    Swal.fire({
                        position: 'top-end',
                        icon: res.data.cls,
                        title: res.data.msg,
                        showConfirmButton: false,
                        toast:true,
                        timer: 1500
                    })
                })
            }
        })
    }

    useEffect(() => {
        getCategories()
    }, []);


    return (
        <div className="container-fluid px-4">
            <Helmet>
                <title>Category List | Mega Multi Service IO</title>
            </Helmet>
            <BreadCrumb title={'Category List'}/>
            <div className="row justify-content-center">
                <div className="col-md-12">
                    <div className="card my-card mb-5 position-relative">
                        {isLoading ?
                            <div className="card-loader">
                                <img src={loader} alt={'loader'}/>
                            </div> : null
                        }

                        <div className="card-header d-flex justify-content-between">
                            <h4 className={'theme-text'}>Category List</h4>
                           <Link to={'/category/create'}><button className={'btn btn-success btn-sm'}><i className={'fa-solid fa-plus'}/></button></Link>
                        </div>
                        <div className="card-body">
                            {Object.keys(categories).length > 0 ?
                                <table className={'table table-bordered table-hover table-striped'}>
                                    <thead>
                                    <tr>
                                        <th>SL</th>
                                        <th>Name/Slug</th>
                                        <th>Photo/Icon</th>
                                        <th>Status/Serial</th>
                                        <th>Created/Updated At</th>
                                        <th>Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {categories.map((category, index) => (
                                        <tr key={index}>
                                            <td className={'align-middle'}>{startFrom + (index++)}</td>
                                            <td className={'text-start'}>
                                                <p><strong>Name:</strong> {category.name}</p>
                                                <p className={'text-success'}><strong>Name
                                                    ES:</strong> {category.name_es}</p>
                                                <p><strong>Slug:</strong> {category.slug}</p>

                                            </td>
                                            <td>
                                                <figure className="figure">
                                                    <figcaption className="figure-caption">Photo</figcaption>
                                                    <img src={category.photo} alt={category.name}
                                                         className="mt-2 figure-img img-fluid rounded user-photo"/>
                                                </figure>
                                                <figure className="figure ms-3">
                                                    <figcaption className="figure-caption">Mobile Icon</figcaption>
                                                    <img src={category.mobile_icon} alt={category.name}
                                                         className="mt-2 figure-img img-fluid rounded user-photo"/>
                                                </figure>
                                                <p>Icon: <span dangerouslySetInnerHTML={{__html: category.icon}}/>
                                                    <code>
                                                        <pre>{category.icon}</pre>
                                                    </code></p>
                                            </td>
                                            <td>
                                                <p><strong>Status:</strong> {category.status}</p>
                                                <p className={'text-success'}><strong>Order
                                                    By:</strong> {category.order_by}</p>
                                            </td>
                                            <td>
                                                <p className="text-success"><small>{category.created_at}</small></p>
                                                <p className="text-primary"><small>{category.updated_at}</small></p>
                                            </td>
                                            <td>
                                                <Link to={'/category/edit/'+category.id}><button className={'btn btn-sm btn-warning'}><i
                                                    className={'fa-solid fa-edit'}/></button> </Link>
                                                <button onClick={()=>handleCategoryDelete(category.id)} className={'btn btn-sm btn-danger ms-1'}><i
                                                    className={'fa-solid fa-trash'}/></button>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table> : null
                            }
                            {Object.keys(categories).length === 0 && !isLoading ?
                                <div className={'text-center'}><p className={'text-danger'}>No Data Found</p>
                                </div> : null
                            }

                        </div>
                        <div className="card-footer">
                            <nav className={'pagination-sm'}>
                                <Pagination
                                    activePage={activePage}
                                    itemsCountPerPage={itemsCountPerPage}
                                    totalItemsCount={totalItemsCount}
                                    pageRangeDisplayed={5}
                                    onChange={getCategories}
                                    nextPageText="Next"
                                    prevPageText="Previous"
                                    firstPageText="First"
                                    lastPageText="Last"
                                    itemClass="page-item"
                                    linkClass="page-link"
                                />
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CategoryList;
