import React, {useEffect, useState} from 'react';
import {Link, useHistory, useParams} from "react-router-dom";
import axios from "axios";
import Constant from "../../../Constant";
import Swal from "sweetalert2";
import {Helmet} from "react-helmet";
import BreadCrumb from "../../Includes/BreadCrumb";
import $ from 'jquery'

const AddAdmin = () => {
    const params = useParams()
    const history = useHistory()
    const [isLoading, setIsLoading] = useState(false)
    const [input, setInput] = useState({})
    const [errors, setErrors] = useState([])
    const [roles, setRoles] = useState([])


    const getRoles = () => {
        setIsLoading(true)
        axios.get(`${Constant.BASE_URL}/get-roles`).then(res => {
            setRoles(res.data)
            setIsLoading(false)
        })
    }
    const getAdmin = () => {
        setIsLoading(true)
        axios.get(`${Constant.BASE_URL}/get-admin/${params.id}`).then(res => {
            setInput({
                'name': res.data.data.name,
                'email': res.data.data.email,
                'role': res.data.data.role_id
            })
            setIsLoading(false)
        })
    }
    const handleInput = (e) => {
        setInput(prevState => ({...prevState, [e.target.name]: e.target.value}))
    }

    const handleCategoryCreate = () => {

        setIsLoading(true)
        axios({
            method: 'put',
            url: `${Constant.BASE_URL}/update-admin/${params.id}`,
            data: input
        }).then(res => {
            setIsLoading(false)
            Swal.fire({
                position: 'top-end',
                icon: res.data.cls,
                title: res.data.msg,
                showConfirmButton: false,
                toast: true,
                timer: 1500
            })
            history.push('/admin-list')
        }).catch(errors => {
            setIsLoading(false)
            if (errors.response.status == 422) {
                setErrors(errors.response.data.errors)
            }
        })
    }

    const handlePasswordShow = () => {
        let type = $('#password').attr('type')
        if (type == 'password') {
            type = 'text'
        } else {
            type = 'password'
        }
        $('#password').attr('type', type)
    }

    useEffect(() => {
        getRoles()
        getAdmin()
    }, []);


    return (
        <div className="container-fluid px-4">
            <Helmet>
                <title>Edit Admin | Mega Multi Service IO</title>
            </Helmet>
            <BreadCrumb title={'Edit Admin'}/>
            <div className="row justify-content-center">
                <div className="col-md-12">
                    <div className="card my-card mb-5 position-relative">
                        <div className="card-header d-flex justify-content-between">
                            <h4 className={'theme-text'}>Edit Admin</h4>
                            <Link to={'/admin-list'}>
                                <button className={'btn btn-success btn-sm'}><i className={'fa-solid fa-list'}/>
                                </button>
                            </Link>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <label className={'w-100 mt-4'}>
                                        <p>Name</p>
                                        <input
                                            className={errors.name != undefined ? 'form-control mt-2 is-invalid' : 'form-control mt-2'}
                                            type={'text'}
                                            name={'name'}
                                            value={input.name}
                                            onChange={handleInput}
                                            placeholder={'Enter name'}
                                        />
                                        <p className={'login-error-msg'}>
                                            <small>{errors.name != undefined ? errors.name[0] : null}</small></p>
                                    </label>
                                </div>
                                <div className="col-md-6">
                                    <label className={'w-100 mt-4'}>
                                        <p>Email</p>
                                        <input
                                            className={errors.email != undefined ? 'form-control mt-2 is-invalid' : 'form-control mt-2'}
                                            type={'text'}
                                            name={'email'}
                                            value={input.email}
                                            onChange={handleInput}
                                            placeholder={'Enter email'}
                                        />
                                        <p className={'login-error-msg'}>
                                            <small>{errors.email != undefined ? errors.email[0] : null}</small></p>
                                    </label>
                                </div>
                                <div className="col-md-6">
                                    <label className={'w-100 mt-4'}>
                                        <p>Select Roles</p>
                                        <select
                                            className={errors.role != undefined ? 'form-select mt-2 is-invalid' : 'form-select mt-2'}

                                            name={'role'}
                                            value={input.role}
                                            onChange={handleInput}
                                        >
                                            <option>Select Role</option>
                                            {roles.map((role, index) => (
                                                <option key={index} value={role.id}>{role.name}</option>
                                            ))}
                                        </select>
                                        <p className={'login-error-msg'}>
                                            <small>{errors.role != undefined ? errors.role[0] : null}</small></p>
                                    </label>
                                </div>
                                {/*<div className="col-md-6">*/}
                                {/*    <label className={'w-100 mt-4'}>*/}
                                {/*        <p>Password</p>*/}
                                {/*        <div className="input-group mt-2">*/}
                                {/*            <input*/}
                                {/*                className={errors.password != undefined ? 'form-control  is-invalid' : 'form-control '}*/}
                                {/*                type={'password'}*/}
                                {/*                name={'password'}*/}
                                {/*                value={input.password}*/}
                                {/*                onChange={handleInput}*/}
                                {/*                placeholder={'Enter Password'}*/}
                                {/*                id={'password'}*/}
                                {/*            />*/}
                                {/*            <div onClick={handlePasswordShow} className="input-group-text"><i*/}
                                {/*                className={'fa-solid fa-eye'}/></div>*/}
                                {/*        </div>*/}

                                {/*        <p className={'login-error-msg'}>*/}
                                {/*            <small>{errors.password != undefined ? errors.password[0] : null}</small>*/}
                                {/*        </p>*/}
                                {/*    </label>*/}
                                {/*</div>*/}

                                <div className="col-md-12">
                                    <div className="row justify-content-center">
                                        <div className="col-md-4">
                                            <div className="d-grid mt-4">
                                                <button className={'btn btn-outline-success'}
                                                        onClick={handleCategoryCreate}
                                                        dangerouslySetInnerHTML={{__html: isLoading ? '<span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>' : `Update Admin`}}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddAdmin;
