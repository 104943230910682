import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import BreadCrumb from "../../Includes/BreadCrumb";
import axios from "axios";
import Constant from "../../../Constant";
import Pagination from "react-js-pagination";
import Swal from "sweetalert2";
import loader from './../../../Assets/Image/loader/loader.svg'

const UserList = () => {

    const [users, setUsers] = useState([])
    const [startFrom, setStartFrom] = useState(1)
    const [itemsCountPerPage, setItemsCountPerPage] = useState(0)
    const [totalItemsCount, setTotalItemsCount] = useState(0)
    const [activePage, setActivePage] = useState(1)
    const [isLoading, setIsLoading] = useState(false)

    const getListings = (pageNumber, type = 'regular') => {
        setActivePage(pageNumber)
        setIsLoading(true)
        axios.get(`${Constant.BASE_URL}/user?page=${pageNumber}`).then(res => {
            setUsers(res.data.data)
            setItemsCountPerPage(parseInt(res.data.meta.per_page))
            setTotalItemsCount(res.data.meta.total)
            setIsLoading(false)
            setStartFrom(res.data.meta.from)
        })
    }

    const handleListingDelete = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "user Will be deleted and also associated all data will be deleted",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`${Constant.BASE_URL}/user/${id}`).then(res => {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'warning',
                        toast: true,
                        title: res.data.msg,
                        showConfirmButton: false,
                        timer: 3000
                    })
                    getListings(1)
                })
            }
        })
    }

    useEffect(() => {
        getListings(1)
    }, [])


    return (
        <div className="container-fluid px-4">
            <Helmet>
                <title>User List | Mega Multi Service IO</title>
            </Helmet>
            <BreadCrumb title={'user List'}/>
            <div className="row justify-content-center">
                <div className="col-md-12">
                    <div className="card my-card mb-5 position-relative">
                        {isLoading ?
                            <div className="card-loader">
                                <img src={loader} alt={'loader'}/>
                            </div> : null
                        }

                        <div className="card-header d-flex justify-content-between">
                            <h4 className={'theme-text'}>User List</h4>
                        </div>
                        <div className="card-body">
                            {Object.keys(users).length > 0 ?
                                <table className={'table table-sm table-bordered table-hover table-striped'}>
                                    <thead>
                                    <tr>
                                        <th>SL</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Phone</th>
                                        <th>Photo</th>
                                        <th>Registered/Updated At</th>
                                        <th>Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {users.map((user, index) => (
                                        <tr key={index}>
                                            <td className={'align-middle'}>{startFrom + (index++)}</td>
                                            <td>{user.name}</td>
                                            <td>{user.email}</td>
                                            <td>{user.phone}</td>
                                            <td><img src={user.photo} alt={user.name} className="img-thumbnail user-photo"/></td>
                                            <td>
                                                <p className="text-success"><small>{user.created_at}</small></p>
                                                <p className="text-primary"><small>{user.updated_at}</small></p>
                                            </td>
                                            <td>Action</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table> :null
                            }
                            {Object.keys(users).length === 0 && !isLoading ?
                                <div className={'text-center'}><p className={'text-danger'}>No Data Found</p></div>:null
                            }

                        </div>
                        <div className="card-footer">
                            <nav className={'pagination-sm'}>
                                <Pagination
                                    activePage={activePage}
                                    itemsCountPerPage={itemsCountPerPage}
                                    totalItemsCount={totalItemsCount}
                                    pageRangeDisplayed={5}
                                    onChange={getListings}
                                    nextPageText="Next"
                                    prevPageText="Previous"
                                    firstPageText="First"
                                    lastPageText="Last"
                                    itemClass="page-item"
                                    linkClass="page-link"
                                />
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserList;
