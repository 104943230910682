import React, {useEffect, useState} from 'react';
import BreadCrumb from "../Includes/BreadCrumb";
import {Helmet} from "react-helmet";
import axios from "axios";
import Constant from "../../Constant";
import {Link} from "react-router-dom";

const Home = () => {

    const [data, setData] = useState([])

    const getDashboard = () => {
        axios.get(`${Constant.BASE_URL}/get-dashboard`).then(res => {
            setData(res.data)
        })
    }

    useEffect(() => {
        getDashboard()
    }, []);


    return (
        <div className="container-fluid px-4">
            <Helmet>
                <title>Dashboard | Mega Multi Service IO</title>
            </Helmet>
            <BreadCrumb title={'Dashboard'}/>
            <div className="row">
                <div className="col-xl-4">
                    <div className="card bg-primary text-white mb-4 text-center">
                        <div className="card-header">
                            <h4>Total List</h4>
                        </div>
                        <div className="card-body">
                           <h1>{data.listing}</h1>
                        </div>
                        <div className="card-footer d-flex align-items-center justify-content-center">
                            <Link className="small text-white stretched-link" to="/listing-list">View Details</Link>
                            <div className="small text-white ms-3"><i className="fas fa-angle-right"></i></div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4">
                    <div className="card bg-warning text-white mb-4 text-center">
                        <div className="card-header">
                            <h4>Total User</h4>
                        </div>
                        <div className="card-body">
                           <h1>{data.user}</h1>
                        </div>
                        <div className="card-footer d-flex align-items-center justify-content-center">
                            <Link className="small text-white stretched-link" to="/user-list">View Details</Link>
                            <div className="small text-white ms-3"><i className="fas fa-angle-right"></i></div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4">
                    <div className="card bg-info text-white mb-4 text-center">
                        <div className="card-header">
                            <h4>Total Booking</h4>
                        </div>
                        <div className="card-body">
                           <h1>{data.contact}</h1>
                        </div>
                        <div className="card-footer d-flex align-items-center justify-content-center">
                            <Link className="small text-white stretched-link" to="/contact-list">View Details</Link>
                            <div className="small text-white ms-3"><i className="fas fa-angle-right"></i></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;
