import React, {useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import {Helmet} from "react-helmet";
import BreadCrumb from "../../Includes/BreadCrumb";
import axios from "axios";
import Constant from "../../../Constant";

const ListingDetails = () => {
    const params = useParams()
    const [listing, setListing] = useState([])
    const getListing = () => {
        axios.get(Constant.BASE_URL + '/listing/' + params.id).then(res => {
            setListing(res.data.data)
        })
    }

    useEffect(() => {
        getListing()
    }, []);


    return (
        <div className="container-fluid px-4">
            <Helmet>
                <title>Listing Details | Mega Multi Service IO</title>
            </Helmet>
            <BreadCrumb title={'Listing Details'}/>
            <div className="row justify-content-center">
                <div className="col-md-12">
                    <div className="card my-card mb-5 position-relative">
                        <div className="card-header d-flex justify-content-between">
                            <h4 className={'theme-text'}>Listing Details</h4>
                            <Link to={'/listing-list'}>
                                <button className={'btn btn-success btn-sm'}><i className={'fa-solid fa-list'}/>
                                </button>
                            </Link>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12 mb-4">
                                    <div className="card">
                                        <div className="card-header">
                                            <h4>Listing Basic Information</h4>
                                        </div>
                                        <div className="card-body">
                                            <table className={'table table-left-align table-bordered table-hover table-striped'}>
                                                <tbody>
                                                    <tr>
                                                        <th>Title</th>
                                                        <td>{listing.title}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Title ES</th>
                                                        <td>{listing.title_es}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Sub Title</th>
                                                        <td>{listing.sub_title}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Sub Title ES</th>
                                                        <td>{listing.sub_title_es}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Description</th>
                                                        <td>{listing.description}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Description ES</th>
                                                        <td>{listing.description_es}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Category</th>
                                                        <td>{listing?.category?.name}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>User</th>
                                                        <td>{listing?.user?.name}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Created At</th>
                                                        <td>{listing?.created_at}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Updated At</th>
                                                        <td>{listing?.updated_at}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="card">
                                        <div className="card-header">
                                            <h4>Listing information</h4>
                                        </div>
                                        <div className="card-body">
                                            {listing.information != undefined ?
                                                <>
                                                    <tabe className={'table table-bordered table-hover table-striped'}>
                                                        <tbody>
                                                        <tr>
                                                            <th>Address</th>
                                                            <td>{listing.information?.address}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Facebook</th>
                                                            <td>{listing.information?.facebook_url}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Instagram</th>
                                                            <td>{listing.information?.instagram_url}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Twitter</th>
                                                            <td>{listing.information?.twitter_url}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Wage</th>
                                                            <td>{listing.information?.wage_url}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Website</th>
                                                            <td>{listing.information?.website_url}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>WhatsApp</th>
                                                            <td>{listing.information?.whatssap_url}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Map</th>
                                                            <td>{listing.information?.map_url}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Phone</th>
                                                            <td>{listing.information?.phone}</td>
                                                        </tr>
                                                        </tbody>
                                                    </tabe>
                                                </> : null
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="card">
                                        <div className="card-header">
                                            <p className={"mb-0"}><i className="fa-regular fa-clock"></i> Opening Hours
                                            </p>
                                        </div>
                                        <div className="card-body">
                                            <ul className={'list-group'}>
                                                {listing.time != undefined ? listing.time.map((time, index) => (
                                                    <li key={index} className={'list-group-item'}>
                                                        <div className="d-flex justify-content-between">
                                                            <div className="w-50">{time.day}</div>
                                                            <div className="w-50">
                                                                <div className="d-flex justify-content-between">
                                                                    <div>
                                                                        {time.open != 'Closed' ?
                                                                            <i className="fa-solid fa-door-open me-1 text-success"></i> :
                                                                            <i className="fa-solid fa-door-closed me-1 text-danger"></i>
                                                                        }
                                                                        {time.open}
                                                                    </div>
                                                                    <div>
                                                                        {time.close != null ?
                                                                            <>
                                                                                <i className="fa-solid fa-door-closed me-1 text-danger"></i> {time.close}
                                                                            </> : null
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                )) : null}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="card mt-4">
                                        <div className="card-header">
                                            <h4>Gallery</h4>
                                        </div>
                                        <div className="card-body">
                                            {listing.photos != undefined ? listing.photos.map((photo, index)=>(
                                                <img key={index} src={photo.photo} className={"img-thumbnail details-photo-item"} alt={"gallery"}/>
                                            )):null}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ListingDetails;
