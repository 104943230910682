import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import BreadCrumb from "../../Includes/BreadCrumb";
import axios from "axios";
import Constant from "../../../Constant";
import Swal from "sweetalert2";

const AccessControl = () => {
    const [admins, setAdmins] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [input, setInput] = useState({})
    const [errors, setErrors] = useState([])
    const [roles, setRoles] = useState([])
    const [permissions, setPermissions] = useState([])
    const [checkboxValues, setCheckboxValues] = useState([]);
    const [role_id, set_role_id] = useState(0)
    const [selected_roles, set_selected_roles] = useState([])


    const handleRoleInput = (e) => {
        set_role_id(e.target.value)
        roles.find((role) => {
            if (role.id == e.target.value && role.permissions) {
                let data = []
                role.permissions.forEach((permission) => {
                    data.push(permission.id)
                })
                set_selected_roles(data)
            }
        })
    }


    const handleCheckboxChange = (index) => {

        set_selected_roles((prevRoles) =>
            prevRoles.includes(index)
                ? prevRoles.filter((i) => i !== index) // Remove index if already present
                : [...prevRoles, index] // Add index if not present
        );

        setCheckboxValues((prevIndexes) =>
            prevIndexes.includes(index)
                ? prevIndexes.filter((i) => i !== index) // Remove index if already present
                : [...prevIndexes, index] // Add index if not present
        );
    };

    const syncPermission = () => {
        const data = {
            permissions: selected_roles,
            role_id : role_id
        }
        axios.post(`${Constant.BASE_URL}/sync-role-permission`, data).then(res => {
            Swal.fire({
                position: 'top-end',
                icon: 'warning',
                toast: true,
                title: res.data.message,
                showConfirmButton: false,
                timer: 3000
            })
        })
    }

    useEffect(() => {
        console.log(selected_roles)
    }, [selected_roles]);

    const handleInput = (e) => {
        if (e.target.name == 'id') {
            if (!isNaN(e.target.value)) {
                let data = admins.filter(admin => `${admin.id}`.includes(e.target.value))
                setInput(prevState => ({...prevState, role: data[0]['role_id']}))
            } else {
                setInput(prevState => ({...prevState, role: 0}))
            }
        }
        setInput(prevState => ({...prevState, [e.target.name]: e.target.value}))
    }
    const getRoles = () => {
        setIsLoading(true)
        axios.get(`${Constant.BASE_URL}/get-roles`).then(res => {
            setRoles(res.data)
            setIsLoading(false)
        })
    }
    const getAdmin = () => {
        setIsLoading(true)
        axios.get(`${Constant.BASE_URL}/get-admin`).then(res => {
            setAdmins(res.data.data)
            setIsLoading(false)
        })
    }

    const getPermissions = () => {
        setIsLoading(true)
        axios.get(`${Constant.BASE_URL}/permissions`).then(res => {
            setPermissions(res.data)
            setIsLoading(false)
        })
    }

    const handleRoleAssign = () => {
        setIsLoading(true)
        axios.post(`${Constant.BASE_URL}/role-permissions-assign`, input).then(res => {
            getAdmin()
            setIsLoading(false)
        }).catch(errors => {
            setIsLoading(false)
            if (errors.response.status == 422) {
                setErrors(errors.response.data.errors)
            }
        })
    }



    useEffect(() => {
        getAdmin()
        getRoles()
        getPermissions()
    }, []);

    return (
        <div className={'container'}>
            <Helmet>
                <title>Access Control | Mega Multi Service IO</title>
            </Helmet>
            <BreadCrumb title={'Access Control'}/>
            <div className="row">
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-header">
                            <h4>Admin Role Association</h4>
                        </div>
                        <div className="card-body">
                            <label className={'w-100 mt-4'}>
                                <p>Select Admin</p>
                                <select
                                    className={errors.id != undefined ? 'form-select mt-2 is-invalid' : 'form-select mt-2'}
                                    name={'admin_id'}
                                    value={input.admin_id}
                                    onChange={handleInput}
                                >
                                    <option>Select Admin</option>
                                    {admins.map((admin, index) => (
                                        <option key={index} value={admin.id}>{admin.name} - {admin.email}</option>
                                    ))}
                                </select>
                                <p className={'login-error-msg'}>
                                    <small>{errors.admin_id != undefined ? errors.admin_id[0] : null}</small></p>
                            </label>
                            <label className={'w-100 mt-1'}>
                                <p>Select Role</p>
                                <select
                                    className={errors.role != undefined ? 'form-select mt-2 is-invalid' : 'form-select mt-2'}
                                    name={'role_id'}
                                    value={input.role_id}
                                    onChange={handleInput}
                                >
                                    <option>Select Role</option>
                                    {roles.map((role, key) => (
                                        <option key={key} value={role.id}>{role.name}</option>
                                    ))}
                                </select>
                                <p className={'login-error-msg'}>
                                    <small>{errors.role_id != undefined ? errors.role_id[0] : null}</small></p>
                            </label>
                            <div className="d-grid">
                                <button className={'btn btn-outline-success'}
                                        onClick={handleRoleAssign}
                                        dangerouslySetInnerHTML={{__html: isLoading ? '<span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>' : `Update`}}/>

                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header">
                            <h4>Admin list with roles</h4>
                        </div>
                        <div className="card-body">
                            <table className={'table table-bordered table-striped table-hover'}>
                                <thead>
                                <tr>
                                    <th>SL</th>
                                    <th>Name/Email</th>
                                    <th>Role</th>
                                </tr>
                                </thead>
                                <tbody>
                                {admins.map((admin, index) => (
                                    <tr>
                                        <td>{index + 1}</td>
                                        <td className={'text-start'}>
                                            <p>{admin.name} - {admin.email}</p>
                                        </td>
                                        <td>{admin.role_name}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-header">
                            <h4>Role Based Permissions</h4>
                        </div>
                        <div className="card-body">
                            <label className={'w-100 mt-4'}>
                                <p>Select Role</p>
                                <select
                                    className={errors.role != undefined ? 'form-select mt-2 is-invalid' : 'form-select mt-2'}
                                    name={'role'}
                                    value={input.role}
                                    onChange={handleRoleInput}
                                >
                                    <option>Select Role</option>
                                    {roles.map((role, key) => (
                                        <option key={key} value={role.id}>{role.name}</option>
                                    ))}
                                </select>
                                <p className={'login-error-msg'}>
                                    <small>{errors.role != undefined ? errors.role[0] : null}</small></p>
                            </label>

                            <div className="row">
                                {permissions.map((permission, index) => (
                                    <div className="col-6">
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id={`flexCheckDefault_${permission.id}`}
                                                checked={selected_roles.includes(permission.id)}
                                                onChange={() => handleCheckboxChange(permission.id)}
                                            />
                                            <label
                                                className="form-check-label text-capitalize cursor-pointer mb-0"
                                                htmlFor={`flexCheckDefault_${permission.id}`}
                                            >
                                                {permission.name.replace('_', ' ')}
                                            </label>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="d-grid">
                                <button onClick={syncPermission} className={'btn btn-success mt-3'}>Update</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default AccessControl;
