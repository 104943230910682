import React, {useEffect, useState} from 'react';
import axios from "axios";
import Constants from "../../../Constant";
import {Link, useParams} from "react-router-dom";
import {Helmet} from "react-helmet";
import BreadCrumb from "../../Includes/BreadCrumb";

const SelectCategory = () => {
    const params = useParams()
    const [services, set_services] = useState([])
    const getService = () => {
        axios.get(Constants.BASE_URL + '/get-service-category').then(res => {
            set_services(res.data.data)
        })
    }
    useEffect(()=>{
        getService()
    }, [])

    return (
        <div>
            <div className="container">
                <Helmet>
                    <title>Pricing | Mega Multi Service IO</title>
                </Helmet>
                <BreadCrumb title={'Choose a Package'}/>
                <div className="row mt-5">
                    {services.map((service, index)=>(
                        <div className="col-md-4" key={index}>
                            <div className="card my-card-front">
                                <div className="card-header my-card-header">
                                    <h4>{service.title}</h4>
                                </div>
                                <div className="card-body">
                                    <h1>${service.price}</h1>
                                    <ul>
                                        <li>One listing submission</li>
                                        <li>{service.validate}  days expiration</li>
                                        <li>Submit your business</li>
                                        <li>Create events</li>
                                        <li>Rent real estate</li>
                                    </ul>
                                    <div className="d-grid">
                                        <Link className={'w-100'} to={'/add-listing/'+service.slug+'/'+params.type_id}><button className={'w-100'}>Buy Package<i className="fa-solid fa-arrow-right ms-4"></i></button></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}


                </div>
            </div>
        </div>
    );
};

export default SelectCategory;
