import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import BreadCrumb from "../../Includes/BreadCrumb";
import loader from "../../../Assets/Image/loader/loader.svg";
import {Link, useHistory, useParams} from "react-router-dom";
import Constant from "../../../Constant";
import axios from "axios";
import Swal from "sweetalert2";

const CategoryAdd = () => {

    const params = useParams()
    const history = useHistory()
    const [isEditMode, setIsEditMode] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [input, setInput] = useState({status: 1})
    const [errors, setErrors] = useState([])
    const [title, setTitle] = useState('Add')
    const [category, setCategory] = useState([])
    const [method, setMethod] = useState('post')

    const handleInput = (e) => {
        if (e.target.name == 'name') {
            let slug = e.target.value
            slug = slug.toLowerCase()
            slug = slug.replaceAll(' ', '-')
            setInput(prevState => ({...prevState, slug: slug}))
        }
        setInput(prevState => ({...prevState, [e.target.name]: e.target.value}))
    }

    const handlePhoto = (e) => {
        let file = e.target.files[0]
        let reader = new FileReader()
        reader.onloadend = () => {
            setInput(prevState => ({...prevState, [e.target.name]: reader.result}))
        }
        reader.readAsDataURL(file)
    }
    const handleCategoryCreate = () => {

        setIsLoading(true)
        axios({
            method: method,
            url: isEditMode ? `${Constant.BASE_URL}/category/${params.id}`:`${Constant.BASE_URL}/category`,
            data: input
        }).then(res => {
            setIsLoading(false)
            Swal.fire({
                position: 'top-end',
                icon: res.data.cls,
                title: res.data.msg,
                showConfirmButton: false,
                toast: true,
                timer: 1500
            })
            history.push('/category')
        }).catch(errors => {
            setIsLoading(false)
            if (errors.response.status == 422) {
                setErrors(errors.response.data.errors)
            }
        })
    }

    const getCategory = (category_id) => {
        axios.get(`${Constant.BASE_URL}/category/${category_id}`).then(res => {
            setInput({
                name: res.data.name,
                name_es: res.data.name_es,
                slug: res.data.slug,
                status: res.data.status,
                order_by: res.data.order_by,
                icon: res.data.icon,
            })
            setCategory(res.data)
        })
    }

    useEffect(() => {
        if (params.id != undefined) {
            setIsEditMode(true)
            setTitle('Edit')
            getCategory(params.id)
            setMethod('put')
        }
    }, []);


    return (
        <div className="container-fluid px-4">
            <Helmet>
                <title>{title} Category | Mega Multi Service IO</title>
            </Helmet>
            <BreadCrumb title={`${title} Category`}/>
            <div className="row justify-content-center">
                <div className="col-md-12">
                    <div className="card my-card mb-5 position-relative">
                        {isLoading ? <div className="card-loader">
                            <img src={loader} alt={'loader'}/>
                        </div> : null}
                        <div className="card-header d-flex justify-content-between">
                            <h4 className={'theme-text'}>{title} Category</h4>
                            <Link to={'/category'}>
                                <button className={'btn btn-success btn-sm'}><i className={'fa-solid fa-list'}/>
                                </button>
                            </Link>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <label className={'w-100 mt-4'}>
                                        <p>Name</p>
                                        <input
                                            className={errors.name != undefined ? 'form-control mt-2 is-invalid' : 'form-control mt-2'}
                                            type={'text'}
                                            name={'name'}
                                            value={input.name}
                                            onChange={handleInput}
                                            placeholder={'Enter category name'}
                                        />
                                        <p className={'login-error-msg'}>
                                            <small>{errors.name != undefined ? errors.name[0] : null}</small></p>
                                    </label>
                                </div>
                                <div className="col-md-6">
                                    <label className={'w-100 mt-4'}>
                                        <p>Name ES</p>
                                        <input
                                            className={errors.name_es != undefined ? 'form-control mt-2 is-invalid' : 'form-control mt-2'}
                                            type={'text'}
                                            name={'name_es'}
                                            value={input.name_es}
                                            onChange={handleInput}
                                            placeholder={'Enter category name in Spanish'}
                                        />
                                        <p className={'login-error-msg'}>
                                            <small>{errors.name_es != undefined ? errors.name_es[0] : null}</small></p>
                                    </label>
                                </div>
                                <div className="col-md-6">
                                    <label className={'w-100 mt-4'}>
                                        <p>Slug</p>
                                        <input
                                            className={errors.slug != undefined ? 'form-control mt-2 is-invalid' : 'form-control mt-2'}
                                            type={'text'}
                                            name={'slug'}
                                            value={input.slug}
                                            onChange={handleInput}
                                            placeholder={'Enter category slug'}
                                        />
                                        <p className={'login-error-msg'}>
                                            <small>{errors.slug != undefined ? errors.slug[0] : null}</small></p>
                                    </label>
                                </div>
                                <div className="col-md-6">
                                    <label className={'w-100 mt-4'}>
                                        <p>Serial</p>
                                        <input
                                            className={errors.order_by != undefined ? 'form-control mt-2 is-invalid' : 'form-control mt-2'}
                                            type={"number"}
                                            name={'order_by'}
                                            value={input.order_by}
                                            onChange={handleInput}
                                            placeholder={'Enter category serial'}
                                        />
                                        <p className={'login-error-msg'}>
                                            <small>{errors.order_by != undefined ? errors.order_by[0] : null}</small>
                                        </p>
                                    </label>
                                </div>
                                <div className="col-md-6">
                                    <label className={'w-100 mt-4'}>
                                        <p>Status</p>
                                        <select
                                            className={errors.status != undefined ? 'form-select mt-2 is-invalid' : 'form-select mt-2'}
                                            name={'status'}
                                            value={input.status}
                                            onChange={handleInput}
                                            placeholder={'Select category status'}
                                        >
                                            <option value={1}>Active</option>
                                            <option value={0}>Inactive</option>
                                        </select>
                                        <p className={'login-error-msg'}>
                                            <small>{errors.status != undefined ? errors.status[0] : null}</small></p>
                                    </label>
                                </div>
                                <div className="col-md-6">
                                    <label className={'w-100 mt-4'}>
                                        <p>
                                            Icon
                                            <small className={'ms-2'}>
                                                Example: <code>{'<i class="fa-solid fa-house-user"></i>'}</code>
                                            </small>
                                            <small className={'ms-2'}>
                                                <a target={"_blank"} href={'https://fontawesome.com/icons'}>Get
                                                    Codes</a>
                                            </small>
                                        </p>
                                        <input
                                            className={errors.icon != undefined ? 'form-control mt-2 is-invalid' : 'form-control mt-2'}
                                            name={'icon'}
                                            value={input.icon}
                                            onChange={handleInput}
                                            placeholder={'Enter fontawesome icon'}
                                        />
                                        <p className={'login-error-msg'}>
                                            <small>{errors.icon != undefined ? errors.icon[0] : null}</small></p>
                                    </label>
                                </div>
                                <div className="col-md-6">
                                    <label className={'w-100 mt-4'}>
                                        <p>Photo</p>
                                        <input
                                            className={errors.photo != undefined ? 'form-control mt-2 is-invalid' : 'form-control mt-2'}
                                            type={'file'}
                                            name={'photo'}
                                            onChange={handlePhoto}
                                        />
                                        <p className={'login-error-msg'}>
                                            <small>{errors.photo != undefined ? errors.photo[0] : null}</small></p>
                                    </label>
                                    {input.photo != undefined || category.photo != undefined? <div className="row">
                                        <div className="col-6">
                                            <div className="photo-preview mt-3">
                                                <img alt={'Photo preview'} src={input.photo != undefined ? input.photo : category.photo}
                                                     className={'img-thumbnail aspect-one'}/>
                                            </div>
                                        </div>
                                    </div> : null}
                                </div>
                                <div className="col-md-6">
                                    <label className={'w-100 mt-4'}>
                                        <p>Mobile Icon</p>
                                        <input
                                            className={errors.mobile_icon != undefined ? 'form-control mt-2 is-invalid' : 'form-control mt-2'}
                                            type={'file'}
                                            name={'mobile_icon'}
                                            onChange={handlePhoto}
                                        />
                                        <p className={'login-error-msg'}>
                                            <small>{errors.mobile_icon != undefined ? errors.mobile_icon[0] : null}</small>
                                        </p>
                                    </label>
                                    {input.mobile_icon != undefined || category.mobile_icon != undefined? <div className="row">
                                        <div className="col-6">
                                            <div className="photo-preview mt-3">
                                                <img alt={'Photo preview'} src={input.mobile_icon != undefined ? input.mobile_icon : category.mobile_icon}
                                                     className={'img-thumbnail aspect-one'}/>
                                            </div>
                                        </div>
                                    </div> : null}
                                </div>
                                <div className="col-md-12">
                                    <div className="row justify-content-center">
                                        <div className="col-md-4">
                                            <div className="d-grid mt-4">
                                                <button className={'btn btn-outline-success'}
                                                        onClick={handleCategoryCreate}
                                                        dangerouslySetInnerHTML={{__html: isLoading ? '<span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> Loading...' : isEditMode ? 'Update Category' : 'Add Category'}}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CategoryAdd;
