import React, {useEffect, useState} from 'react';
import axios from "axios";
import Constants from "../../../Constant";
import $ from "jquery"
import Swal from "sweetalert2";
import {useHistory, useParams} from "react-router-dom";
import {Helmet} from "react-helmet";
import BreadCrumb from "../../Includes/BreadCrumb";

const AddListing = () => {
    const params = useParams()
    const history = useHistory()

    const [days, setDays] = useState([])
    const [times, setTime] = useState([])
    const [input, setInput] = useState({})
    const [photos, setPhotos] = useState([])
    const [errors, setErrors] = useState([])
    const [categories, setCategories] = useState([])
    const [cities, setCities] = useState([])
    const [dateTime, setDateTime] = useState({})
    const [loader, setLoader] = useState(false)
    const [subCities, setSubCities] = useState([])
    const [users, setUsers] = useState([])

    const getUsers = () => {

        axios.get(Constants.BASE_URL + `/get-providers`).then(res => {
            setUsers(res.data)
        })
    }
    const handleImageUpload = (e) => {
        let files = e.target.files
        for (let i = 0; i < Object.keys(files).length; i++) {
            let reader = new FileReader()
            reader.onloadend = () => {
                setPhotos(prevState => [...prevState, reader.result])
            }
            reader.readAsDataURL(files[i])
        }
    }

    const getSubCities = (id) => {
        axios.get(Constants.BASE_URL + `/get-sub-cities/${id}`).then(res => {
            setSubCities(res.data.data)
        })
    }

    const getDays = () => {
        axios.get(Constants.BASE_URL + '/get-days').then(res => {
            setDays(res.data.data)
        })
    }

    const getCities = () => {
        axios.get(Constants.BASE_URL + '/get-cities').then(res => {
            setCities(res.data.data)
        })
    }
    const getCategories = () => {
        axios.get(Constants.BASE_URL + '/get-categories').then(res => {
            setCategories(res.data.data)
        })
    }

    const getTimes = () => {
        axios.get(Constants.BASE_URL + '/get-times').then(res => {
            setTime(res.data.data)
        })
    }

    const handleInput = (e) => {
        setInput(prevState => ({...prevState, [e.target.name]: e.target.value}))
        if (e.target.name == 'city_id'){
            setInput(prevState => ({...prevState, sub_city_id: ''}))
            if (e.target.value != ''){
                getSubCities(e.target.value)
            }else{
                setSubCities([])
            }
        }
    }

    const dateTimeInput = (e, day_id) => {

        let element = $(e.target.dataset.id)
        if (e.target.value == 1 || e.target.value == 2) {
            console.log('if')
            element.slideUp()
        } else {
            element.slideDown()
            console.log('else')
        }

        setDateTime(prevState => ({
            ...prevState, [day_id]: {
                ...prevState[day_id], [e.target.name]: e.target.value
            }
        }))
    }


    const handleListingSubmit = () => {
        setLoader(true)
        axios.post(Constants.BASE_URL + '/listing', input).then(res => {
            if (res.status == 200) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: res.data.msg,
                    toast: true,
                    showConfirmButton: false,
                    timer: 1500
                })
                setLoader(false)
                history.push(`/listing-list`)
            }
        }).catch(errors => {
            if (errors.response.status == 422) {
                setErrors(errors.response.data.errors)
            }
            setLoader(false)
        })
    }


    useEffect(() => {
        setInput(prevState => ({...prevState, opening_time: dateTime}))
    }, [dateTime]);

    useEffect(() => {
        setInput(prevState => ({...prevState, photos: photos}))
    }, [photos]);

    useEffect(() => {
        getDays()
        getTimes()
        getCategories()
        getCities()
        getUsers()
        setInput(prevState => ({...prevState, service_category_slug: params.slug}))
        setInput(prevState => ({...prevState, type_id: params.type_id}))
    }, []);


    return (<div>
        <div className="container section-gap">
            <Helmet>
                <title>Add New Listing | Mega Multi Service IO</title>
            </Helmet>
            <BreadCrumb title={'Add New Listing'}/>
            <div className="row">
                <div className="col-md-12">
                    <div className="card shadow-lg  mt-4">
                        <div className="card-body p-4">
                            <div className="row">
                                <div className="col-md-7">
                                    <div className="card listing-card h-100">
                                        <div className="card-header listing-card-header">
                                            <h4>Basic Information</h4>
                                        </div>
                                        <div className="card-body">
                                            <label className="w-100">
                                                Title
                                                <input
                                                    name={'title'}
                                                    onChange={handleInput}
                                                    value={input.title}
                                                    type={"text"}
                                                    className={'form-control form-control-sm mt-2'}
                                                />
                                                {errors.title != undefined ? <small
                                                    className="text-danger validation-error-message">{errors.title[0]}</small> : null}
                                            </label>
                                            <label className="w-100 mt-3">
                                                Slug
                                                <input
                                                    name={'slug'}
                                                    onChange={handleInput}
                                                    value={input.slug}
                                                    type={"text"}
                                                    className={'form-control form-control-sm mt-2'}
                                                />
                                                {errors.slug != undefined ? <small
                                                    className="text-danger validation-error-message">{errors.slug[0]}</small> : null}
                                            </label>
                                            <label className="w-100 mt-3">
                                                Title ES
                                                <input
                                                    name={'title_es'}
                                                    onChange={handleInput}
                                                    value={input.title_es}
                                                    type={"text"}
                                                    className={'form-control form-control-sm mt-2'}
                                                />
                                                {errors.title_es != undefined ? <small
                                                    className="text-danger validation-error-message">{errors.title_es[0]}</small> : null}
                                            </label>

                                            <label className="w-100 mt-3">
                                                Sub Title
                                                <input
                                                    name={'sub_title'}
                                                    onChange={handleInput}
                                                    value={input.sub_title}
                                                    type={"text"}
                                                    className={'form-control form-control-sm mt-2'}
                                                />
                                                {errors.sub_title != undefined ? <small
                                                    className="text-danger validation-error-message">{errors.sub_title[0]}</small> : null}
                                            </label>

                                            <label className="w-100 mt-3">
                                                Sub Title ES
                                                <input
                                                    name={'sub_title_es'}
                                                    onChange={handleInput}
                                                    value={input.sub_title_es}
                                                    type={"text"}
                                                    className={'form-control form-control-sm mt-2'}
                                                />
                                                {errors.sub_title_es != undefined ? <small
                                                    className="text-danger validation-error-message">{errors.sub_title_es[0]}</small> : null}
                                            </label>
                                            <label className="w-100 mt-3">
                                                Description
                                                <textarea
                                                    name={'description'}
                                                    onChange={handleInput}
                                                    value={input.description}
                                                    className={'form-control'} rows={5}
                                                ></textarea>
                                                {errors.description != undefined ? <small
                                                    className="text-danger validation-error-message">{errors.description[0]}</small> : null}
                                            </label>
                                            <label className="w-100 mt-3">
                                                Description ES
                                                <textarea
                                                    name={'description_es'}
                                                    onChange={handleInput}
                                                    value={input.description_es}
                                                    className={'form-control'} rows={5}
                                                ></textarea>
                                                {errors.description_es != undefined ? <small
                                                    className="text-danger validation-error-message">{errors.description_es[0]}</small> : null}
                                            </label>
                                            <label className="w-100 mt-3">
                                                Select Category
                                                <select
                                                    name={'category_id'}
                                                    onChange={handleInput}
                                                    value={input.category_id}
                                                    className={'form-select form-select-sm'}
                                                >
                                                    <option value="0">Select Category</option>
                                                    {categories.map((category, catIndex) => (<option key={catIndex}
                                                                                                     value={category.id}>{category.name}</option>))}
                                                </select>
                                                {errors.category_id != undefined ? <small
                                                    className="text-danger validation-error-message">{errors.category_id[0]}</small> : null}
                                            </label>
                                            <label className="w-100 mt-3">
                                                Select Country
                                                <select
                                                    name={'city_id'}
                                                    onChange={handleInput}
                                                    value={input.city_id}
                                                    className={'form-select form-select-sm'}
                                                >
                                                    <option value="0">Select Country</option>
                                                    {cities.map((cities, citIndex) => (
                                                        <option key={citIndex} value={cities.id}>{cities.name}</option>))}
                                                </select>
                                                {errors.city_id != undefined ? <small
                                                    className="text-danger validation-error-message">{errors.city_id[0]}</small> : null}
                                            </label>
                                            <label className="w-100 mt-3">
                                                Select City
                                                <select
                                                    name={'sub_city_id'}
                                                    onChange={handleInput}
                                                    value={input.sub_city_id}
                                                    className={'form-select form-select-sm'}
                                                >
                                                    <option value="0">Select City</option>
                                                    {subCities.map((subCity, citIndex) => (
                                                        <option key={citIndex} value={subCity.id}>{subCity.name}</option>))}
                                                </select>
                                                {errors.sub_city_id != undefined ? <small
                                                    className="text-danger validation-error-message">{errors.sub_city_id[0]}</small> : null}
                                            </label>
                                            <label className="w-100 mt-3">
                                                Select Status
                                                <select
                                                    name={'status'}
                                                    onChange={handleInput}
                                                    value={input.status}
                                                    className={'form-select form-select-sm'}
                                                >
                                                    <option value="0">Select Status</option>
                                                    <option value="1">Active</option>
                                                    <option value="0">Inactive</option>
                                                </select>
                                                {errors.status != undefined ? <small
                                                    className="text-danger validation-error-message">{errors.status[0]}</small> : null}
                                            </label>
                                            <label className="w-100 mt-3">
                                                Select User
                                                <select
                                                    name={'user_id'}
                                                    onChange={handleInput}
                                                    value={input.user_id}
                                                    className={'form-select form-select-sm'}
                                                >
                                                    <option value="0">Select User</option>
                                                    {users.map((user, citIndex) => (
                                                        <option key={citIndex} value={user.id}>{user.name}</option>))}
                                                </select>
                                                {errors.user_id != undefined ? <small
                                                    className="text-danger validation-error-message">{errors.user_id[0]}</small> : null}
                                            </label>
                                            <label className="w-100 mt-3">
                                                Select Images
                                                <input multiple={true} onChange={handleImageUpload} type={"file"}
                                                       className={'form-control form-control-sm'}/>
                                            </label>
                                            <div className="image-display-area mt-3">
                                                {Object.keys(photos).length > 0 ? photos.map((photo, index) => (
                                                    <img className={'img-thumbnail me-2 mb-2 preview-image'} src={photo}
                                                         alt={'preview'}/>)) : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div className="card listing-card">
                                        <div className="card-header listing-card-header">
                                            <h4>Opening Hours</h4>
                                        </div>
                                        <div className="card-body">
                                            {days.map((day, dIndex) => (
                                                <div key={dIndex} className="day-input-section mt-1">
                                                    <div className="d-flex align-items-center">
                                                        <label className={'w-25'}>{day.day_name}</label>
                                                        <div className={'w-75'}>
                                                            <div className="d-flex w-100">
                                                                <div className="w-50 p-1">
                                                                    <label><small>Opening Time</small></label>
                                                                    <select data-id={`#end_time_${day.id}`}
                                                                            name={'start'}
                                                                            onChange={(e) => dateTimeInput(e, day.id)}
                                                                            className={'form-select form-select-sm'}>
                                                                        <option>Select Time</option>
                                                                        {times.map((date, index) => (<option key={index}
                                                                                                             value={date.id}>{date.time}</option>))}
                                                                    </select>
                                                                </div>
                                                                <div className="w-50 p-1" id={`end_time_${day.id}`}
                                                                     style={{display: 'none'}}>
                                                                    <label><small>Closing Time</small></label>
                                                                    <select name={'end'}
                                                                            onChange={(e) => dateTimeInput(e, day.id)}
                                                                            className={'form-select form-select-sm'}>
                                                                        <option>Select Time</option>
                                                                        {times.map((date, index) => (<>
                                                                            {date.id !== 1 ? <option key={index}
                                                                                                     value={date.id}>{date.time}</option> : null}
                                                                        </>))}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>))}
                                        </div>
                                    </div>
                                    <div className="card listing-card mt-4">
                                        <div className="card-header listing-card-header">
                                            Social Media Links
                                        </div>
                                        <div className="card-body">
                                            <label className="w-100">
                                                Facebook Page URL
                                                <input
                                                    name={'facebook_url'}
                                                    onChange={handleInput}
                                                    value={input.facebook_url}
                                                    type={"url"}
                                                    className={'form-control form-select-sm mt-2'}
                                                />
                                                {errors.facebook_url != undefined ? <small
                                                    className="text-danger validation-error-message">{errors.facebook_url[0]}</small> : null}
                                            </label>
                                            <label className="w-100 mt-3">
                                                Twitter Page URL
                                                <input
                                                    name={'twitter_url'}
                                                    onChange={handleInput}
                                                    value={input.twitter_url}
                                                    type={"url"}
                                                    className={'form-control form-select-sm mt-2'}
                                                />
                                                {errors.twitter_url != undefined ? <small
                                                    className="text-danger validation-error-message">{errors.twitter_url[0]}</small> : null}
                                            </label>
                                            <label className="w-100 mt-3">
                                                Instagram Page URL
                                                <input
                                                    name={'instagram_url'}
                                                    onChange={handleInput}
                                                    value={input.instagram_url}
                                                    type={"url"}
                                                    className={'form-control form-select-sm mt-2'}
                                                />
                                                {errors.instagram_url != undefined ? <small
                                                    className="text-danger validation-error-message">{errors.instagram_url[0]}</small> : null}
                                            </label>
                                            <label className="w-100 mt-3">
                                                Wage Page URL
                                                <input
                                                    name={'wage_url'}
                                                    onChange={handleInput}
                                                    value={input.wage_url}
                                                    type={"url"}
                                                    className={'form-control form-select-sm mt-2'}
                                                />
                                                {errors.wage_url != undefined ? <small
                                                    className="text-danger validation-error-message">{errors.wage_url[0]}</small> : null}
                                            </label>
                                            <label className="w-100 mt-3">
                                                Whatsapp Page URL
                                                <input
                                                    name={'whatssap_url'}
                                                    onChange={handleInput}
                                                    value={input.whatssap_url}
                                                    type={"url"}
                                                    className={'form-control form-select-sm mt-2'}
                                                />
                                                {errors.whatssap_url != undefined ? <small
                                                    className="text-danger validation-error-message">{errors.whatssap_url[0]}</small> : null}
                                            </label>
                                            <label className="w-100 mt-3">
                                                Google Map URL
                                                <input
                                                    name={'map_url'}
                                                    onChange={handleInput}
                                                    value={input.map_url}
                                                    type={"url"}
                                                    className={'form-control form-select-sm mt-2'}
                                                />
                                                {errors.map_url != undefined ? <small
                                                    className="text-danger validation-error-message">{errors.map_url[0]}</small> : null}
                                            </label>
                                            <label className="w-100 mt-3">
                                                Website URL
                                                <input
                                                    name={'website_url'}
                                                    onChange={handleInput}
                                                    value={input.website_url}
                                                    type={"url"}
                                                    className={'form-control form-select-sm mt-2'}
                                                />
                                                {errors.website_url != undefined ? <small
                                                    className="text-danger validation-error-message">{errors.website_url[0]}</small> : null}
                                            </label>
                                            <label className="w-100 mt-3">
                                                Phone Number
                                                <input
                                                    name={'phone'}
                                                    onChange={handleInput}
                                                    value={input.phone}
                                                    type={"text"}
                                                    className={'form-control form-select-sm mt-2'}
                                                />
                                                {errors.phone != undefined ? <small
                                                    className="text-danger validation-error-message">{errors.phone[0]}</small> : null}
                                            </label>
                                            <label className="w-100 mt-3">
                                                Address
                                                <textarea
                                                    name={'address'}
                                                    onChange={handleInput}
                                                    value={input.address}
                                                    className={'form-control form-select-sm mt-2'}
                                                />
                                                {errors.address != undefined ? <small
                                                    className="text-danger validation-error-message">{errors.address[0]}</small> : null}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="col-md-12">
                                    <div className="d-grid">
                                        <button disabled={loader} onClick={handleListingSubmit} dangerouslySetInnerHTML={{
                                            __html: loader ?
                                                `<div class="spinner-border spinner-border-sm" role="status">
                                                <span class="visually-hidden">Loading...</span>
                                                </div>` : 'Add Listing'
                                        }} className={'btn btn-outline-info'}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>);
};

export default AddListing;
