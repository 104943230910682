import React from 'react';
import {Link} from "react-router-dom";

const SideBar = () => {
    return (
        <div id="layoutSidenav_nav">
            <nav className="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
                <div className="sb-sidenav-menu">
                    <div className="nav">
                        <div className="sb-sidenav-menu-heading">Core</div>
                        <Link className="nav-link" to="/">
                            <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                            Dashboard
                        </Link>
                        <div className="sb-sidenav-menu-heading">Site Content</div>
                        <Link className="nav-link" to={'/listing-list'}>
                            <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                            Listing List
                        </Link>
                        <Link className="nav-link" to={'/select-type'}>
                            <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                            Add Listing
                        </Link>
                        <Link className="nav-link" to={'/category'}>
                            <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                            Category List
                        </Link>
                        <Link className="nav-link" to={'/contact-list'}>
                            <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                            Contact List
                        </Link>
                        <Link className="nav-link" to={'/contact-us-list'}>
                            <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                            Contact Us List
                        </Link>
                        <Link className="nav-link" to={'/user-list'}>
                            <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                            User List
                        </Link>
                        <Link className="nav-link" to="/report">
                            <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                            Report
                        </Link>
                        <Link className="nav-link" to="/access-control">
                            <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                            User Access Control
                        </Link>
                        <Link className="nav-link" to="/admin-list">
                            <div className="sb-nav-link-icon"><i className="fas fa-tachometer-alt"></i></div>
                            Admin List
                        </Link>

                    </div>
                </div>
                <div className="sb-sidenav-footer">
                    <div className="small">Logged in as:</div>
                    {localStorage.admin_name != undefined ? localStorage.admin_name  : null}
                </div>
            </nav>
        </div>
    );
};

export default SideBar;
