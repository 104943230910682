import React from 'react';
import Logo from './../../Assets/Image/logo.jpeg'
import Swal from "sweetalert2";
import axios from "axios";
import Constant from "../../Constant";
import {Link, useHistory} from "react-router-dom";

const Nav = () => {
    const history = useHistory()
    const handleLogout = () => {
        Swal.fire({
            title: 'Are you sure to logout?',
            text: "You will be logged out from admin",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Logout!'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post(`${Constant.BASE_URL}/logout`).then(res=>{
                    localStorage.removeItem('admin_token')
                    localStorage.removeItem('admin_name')
                    localStorage.removeItem('admin_email')
                    history.push('/login')
                    window.location.reload()
                }).catch(errors =>{
                    if (errors.response.status !== 200){
                        localStorage.removeItem('admin_token')
                        localStorage.removeItem('admin_name')
                        localStorage.removeItem('admin_email')
                        history.push('/login')
                        window.location.reload()
                    }
                })
            }
        })
    }

    return (
        <nav className="sb-topnav navbar navbar-expand navbar-dark theme-bg">
            <Link className="navbar-brand active" to="/"><img src={Logo} alt={'Mega Multi Service IO Logo'} className={'logo'}/></Link>
            <button className="btn btn-link btn-sm order-1 order-lg-0 me-4 me-lg-0" id="sidebarToggle" href="#!"><i
                className="fas fa-bars"/></button>
            <form className="d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0">
                <div className="input-group">
                    <input className="form-control form-control-sm" type="text" placeholder="Search for..." aria-label="Search for..." aria-describedby="btnNavbarSearch" />
                    <button className="btn btn-primary btn-sm" id="btnNavbarSearch" type="button"><i className="fas fa-search"></i></button>
                </div>
            </form>
            <ul className="navbar-nav ms-auto ms-md-0 me-3 me-lg-4">
                <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" id="navbarDropdown" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"><i className="fas fa-user fa-fw"></i></a>
                    <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                        {/*<li><a className="dropdown-item" href="#!">Settings</a></li>*/}
                        <li><Link className="dropdown-item" to="/change-password">Change Password</Link></li>
                        {/*<li><hr className="dropdown-divider" /></li>*/}
                        <li><button onClick={handleLogout} className="dropdown-item">Logout</button></li>
                    </ul>
                </li>
            </ul>
        </nav>
    );
};

export default Nav;
