import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import BreadCrumb from "../../Includes/BreadCrumb";
import {Link} from "react-router-dom";
import axios from "axios";
import Constant from "../../../Constant";
import Pagination from "react-js-pagination";
import Swal from "sweetalert2";
import loader from './../../../Assets/Image/loader/loader.svg'

const ListingList = () => {

    const [listings, setListings] = useState([])
    const [startFrom, setStartFrom] = useState(1)
    const [itemsCountPerPage, setItemsCountPerPage] = useState(0)
    const [totalItemsCount, setTotalItemsCount] = useState(0)
    const [activePage, setActivePage] = useState(1)
    const [isLoading, setIsLoading] = useState(false)

    const getListings = (pageNumber, type = 'regular') => {
        setActivePage(pageNumber)
        setIsLoading(true)
        axios.get(`${Constant.BASE_URL}/listing?page=${pageNumber}`).then(res => {
            setListings(res.data.data)
            setItemsCountPerPage(parseInt(res.data.meta.per_page))
            setTotalItemsCount(res.data.meta.total)
            setIsLoading(false)
            setStartFrom(res.data.meta.from)
        })
    }
    const handleListingStatus = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "Listing Status will be changed",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, change it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.get(`${Constant.BASE_URL}/change-listing-status/${id}`).then(res => {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'warning',
                        toast: true,
                        title: res.data.msg,
                        showConfirmButton: false,
                        timer: 3000
                    })
                    getListings(1)
                })
            }
        })
    }
    const handleListingDelete = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "Listing Will be deleted and also associated all data will be deleted",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`${Constant.BASE_URL}/listing/${id}`).then(res => {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'warning',
                        toast: true,
                        title: res.data.msg,
                        showConfirmButton: false,
                        timer: 3000
                    })
                    getListings(1)
                })
            }
        })
    }

    useEffect(() => {
        getListings(1)
    }, [])


    return (
        <div className="container-fluid px-4">
            <Helmet>
                <title>Listing List | Mega Multi Service IO</title>
            </Helmet>
            <BreadCrumb title={'Listing List'}/>
            <div className="row justify-content-center">
                <div className="col-md-12">
                    <div className="card my-card mb-5 position-relative">
                        {isLoading ?
                            <div className="card-loader">
                                <img src={loader} alt={'loader'}/>
                            </div> : null
                        }

                        <div className="card-header d-flex justify-content-between">
                            <h4 className={'theme-text'}>Listing List</h4>
                        </div>
                        <div className="card-body">
                            {Object.keys(listings).length > 0 ?
                                <table className={'table table-sm table-bordered table-hover table-striped'}>
                                    <thead>
                                    <tr>
                                        <th>SL</th>
                                        <th>Title/Slug</th>
                                        <th>Category/Status</th>
                                        <th>Created By</th>
                                        <th>Date Time</th>
                                        <th>Photo</th>
                                        <th>Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {listings.map((listing, index) => (
                                        <tr key={index}>
                                            <td className={'align-middle'}>{startFrom + (index++)}</td>
                                            <td className="text-start">
                                                <p className={'text-success'}><small>Title: {listing.title}</small></p>
                                                <p className={'text-info'}><small>Title Es: {listing.title_es}</small>
                                                </p>
                                                <p className={'text-success'}><small>Slug: {listing.slug}</small></p>
                                            </td>
                                            <td>
                                                <p className={'text-success'}>
                                                    <small>Category: {listing.category}</small></p>
                                                <p className={'text-info'}><small>Status: {listing.status}</small></p>
                                                <p className={'text-info'}><small>Is Approved: <span
                                                    className={listing.is_approved == 1 ? 'text-success' : 'text-danger'}
                                                    dangerouslySetInnerHTML={{__html: listing.is_approved == 1 ? "Yes" : "No"}}/></small>
                                                </p>
                                                <p className={'text-success'}><small>Package: <span
                                                    className="text-tomato">{listing.package}</span></small></p>
                                            </td>
                                            <td>{listing.user}</td>
                                            <td>
                                                <p className={'text-success'}><small>{listing.created_at}</small></p>
                                                <p className={'text-info'}><small>{listing.updated_at}</small></p>
                                            </td>
                                            <td>
                                                <img src={listing.feature_photo} alt={'listing Icon'}
                                                     className={'table-category-icon'}/></td>
                                            <td>
                                                <button
                                                    onClick={() => handleListingStatus(listing.id, listing.is_approved)}
                                                    className={listing.is_approved === 1 ? 'btn btn-sm btn-danger m-1' : 'btn btn-sm btn-info m-1'}
                                                >
                                                    {listing.is_approved === 1 ? <i className="fa-solid fa-xmark"/> :
                                                        <i className="fa-solid fa-check"/>}
                                                </button>
                                                <Link to={`/listing/show/${listing.id}`}>
                                                    <button className={'btn btn-sm btn-success m-1'}><i
                                                        className={'fas fa-eye'}/></button>
                                                </Link>
                                                <Link to={`/edit-listing/${listing.id}`}>
                                                    <button className={'btn btn-sm btn-warning m-1'}><i
                                                        className={'fas fa-edit'}/></button>
                                                </Link>
                                                <button onClick={() => handleListingDelete(listing.id)}
                                                        className={'btn btn-sm btn-danger m-1'}><i
                                                    className={'fas fa-trash'}/></button>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table> : null
                            }
                            {Object.keys(listings).length === 0 && !isLoading ?
                                <div className={'text-center'}><p className={'text-danger'}>No Data Found</p>
                                </div> : null
                            }
                        </div>

                        <div className="card-footer">
                            <nav className={'pagination-sm'}>
                                <Pagination
                                    activePage={activePage}
                                    itemsCountPerPage={itemsCountPerPage}
                                    totalItemsCount={totalItemsCount}
                                    pageRangeDisplayed={5}
                                    onChange={getListings}
                                    nextPageText="Next"
                                    prevPageText="Previous"
                                    firstPageText="First"
                                    lastPageText="Last"
                                    itemClass="page-item"
                                    linkClass="page-link"
                                />
                            </nav>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default ListingList;
