import './Assets/Js/scripts'
import './../node_modules/bootstrap/dist/css/bootstrap.css'
import './Assets/Style/sb_styles.css'
import './Assets/Style/style.scss';
import './App.css';
import Home from "./Components/Pages/Home";
import Nav from "./Components/Includes/Nav";
import SideBar from "./Components/Includes/SideBar";
import Footer from "./Components/Includes/Footer";
import axios from "axios";
import {useEffect, useState} from "react";
import Constant from "./Constant";
import {BrowserRouter, Route} from "react-router-dom";
import Login from "./Components/Pages/Auth/Login";
import Auth from "./Auth";
import ListingList from "./Components/Pages/Listing/ListingList";
import ContactList from "./Components/Pages/Contact/ContactList";
import UserList from "./Components/Pages/User/UserList";
import CategoryList from "./Components/Pages/category/CategoryList";
import CategoryAdd from "./Components/Pages/category/CategoryAdd";
import AddListing from "./Components/Pages/Listing/AddListing";
import SelectListingType from "./Components/Pages/Listing/SelectListingType";
import SelectCategory from "./Components/Pages/Listing/SelectCategory";
import AccessControl from "./Components/Pages/AccessControl/AccessControl";
import AdminList from "./Components/Pages/Admin/AdminList";
import AddAdmin from "./Components/Pages/Admin/AddAdmin";
import ListingDetails from "./Components/Pages/Listing/ListingDetails";
import EditListing from "./Components/Pages/Listing/EditListing";
import ForgetPassword from "./Components/Pages/Auth/ForgetPassword";
import ResetPassword from "./Components/Pages/Auth/ResetPassword";
import ChangePassword from "./Components/Pages/Auth/ChangePassword";
import EditAdmin from "./Components/Pages/Admin/EditAdmin";
import ContactUsList from "./Components/Pages/ContactUs/ContactUsList";


function App() {


    const [url, setUrl] = useState('')
    useEffect(() => {
        setUrl(window.location.pathname)
       // checkAuth()
        setTimeout(()=>{
           // checkAuth()
        }, 900000)
    }, [])

    const [auth, setAuth] = useState(false);
    const checkAuth = () => {
        let url = window.location.pathname
        const token = localStorage.admin_token
        if (url !== '/login') {
            axios.post(Constant.BASE_URL + '/auth-check').then(res => {
                if (res.data.msg === true) {
                    setAuth(res.data.msg)
                } else {
                    localStorage.removeItem('admin_token')
                    localStorage.removeItem('admin_name')
                    localStorage.removeItem('admin_email')
                    window.location.reload()
                }
            }).catch(errors=>{
                // if (errors.response.status === 401){
                    localStorage.removeItem('admin_token')
                    localStorage.removeItem('admin_name')
                    localStorage.removeItem('admin_email')
                    window.location.reload()
                // }
            })
        } else {
            if (token) {
                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            }
        }
    }


    return (
        <>
            {url === '/login' || url ===  '/forgot-password' || url ===  '/reset-password' ?
                <BrowserRouter>
                    <Route exact path={'/login'} component={Login}/>
                    <Route exact path={'/forgot-password'} component={ForgetPassword}/>
                    <Route exact path={'/reset-password'} component={ResetPassword}/>
                </BrowserRouter> :
                <div className="App">
                    <BrowserRouter>
                        <div className="sb-nav-fixed text-start">
                            <Nav/>
                            <div id="layoutSidenav">
                                <SideBar/>
                                <div id="layoutSidenav_content">
                                    <main>
                                        <Auth exact path={'/demo-route'} component={Home}/>
                                        <Auth exact path={'/'} component={Home}/>
                                        <Auth exact path={'/report'} component={Home}/>
                                        <Auth exact path={'/listing-list'} component={ListingList}/>
                                        <Auth exact path={'/add-listing/:slug/:type_id'} component={AddListing}/>
                                        <Auth exact path={'/edit-listing/:id'} component={EditListing}/>
                                        <Auth exact path={'/contact-list'} component={ContactList}/>
                                        <Auth exact path={'/user-list'} component={UserList}/>
                                        <Auth exact path={'/category'} component={CategoryList}/>
                                        <Auth exact path={'/category/edit/:id'} component={CategoryAdd}/>
                                        <Auth exact path={'/category/create'} component={CategoryAdd}/>
                                        <Auth exact path={'/select-type'} component={SelectListingType}/>
                                        <Auth exact path={'/select-category/:type_id'} component={SelectCategory}/>
                                        <Auth exact path={'/access-control'} component={AccessControl}/>
                                        <Auth exact path={'/admin-list'} component={AdminList}/>
                                        <Auth exact path={'/admin/edit/:id'} component={EditAdmin}/>
                                        <Auth exact path={'/admin/create'} component={AddAdmin}/>
                                        <Auth exact path={'/listing/show/:id'} component={ListingDetails}/>
                                        <Auth exact path={'/change-password'} component={ChangePassword}/>
                                        <Auth exact path={'/contact-us-list'} component={ContactUsList}/>
                                    </main>
                                    <Footer/>
                                </div>
                            </div>
                        </div>
                    </BrowserRouter>
                </div>
            }
        </>
    );
}

export default App;

// order percentage -> 15%
// safety deposit - refundable ->
// terms & conditions should be individual
